.experience {
  overflow: hidden;
  padding: 5em 0em 0em 0em;
  background: #fff;
  text-align: left;
}

.experience .section {
  margin: 1em auto;
  padding-bottom: 2em;
  padding-top: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  line-height: 2em;
}

.experience .section ul {
  list-style-type: disc;
}

.experience .section ul,
.experience .section ul li {
  margin-left: 0.5em;
  padding-left: 1em;
}

.experience .no-border {
  border-bottom: 0px;
}

.experience .ant-pagination-item,
.experience .ant-pagination-next,
.experience .ant-pagination-prev {
  padding-left: 0px;
}

.experience .standard-tag {
  cursor: pointer;
}

.experience .search-highlighted {
  background-color: yellow;
  padding: 0;
}
