/*********************************************************************************/
/* Experience                                                                    */
/*********************************************************************************/
.counters {
    margin: 2em auto;
    background-color: #E3F1F9;
    width: 100%;
    height: 230px;
  }

.counter {
    padding: 30px 0;
    font-weight: bold;
    display: block;
    overflow: hidden;
    margin: auto;
    font-size: 48px;
    color: #076598;
    text-align: center;
  }
  
  .counter-title {
    font-weight: bold;
    font-size: 18px;
    color: #222222;
  }