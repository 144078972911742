/*********************************************************************************/
/* Banner                                                                        */
/*********************************************************************************/

#banner {
  padding-top: 1em;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  flex: 1;
}

/*********************************************************************************/
/* home                                                                       */
/*********************************************************************************/

#home {
  overflow: hidden;
  padding: 0em 0em 5em 0em;
  background: #fff;
}

/*********************************************************************************/
/* Welcome                                                                       */
/*********************************************************************************/

#welcome {
  overflow: hidden;
  width: 1000px;
  padding: 6em 100px 0em 100px;
  text-align: center;
  flex: 1;
}

.gm-word {
  font-size: 18px;
}

#welcome .content {
  padding: 0em 8em;
}

#welcome .title h2 {
}

#welcome a,
#welcome strong {
}

/*********************************************************************************/
/* Three Columns                                                                 */
/*********************************************************************************/

#three-column {
  overflow: hidden;
  margin-top: 2em;
  padding-top: 1em;
  /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
  text-align: center;
}

#three-column h2 {
  margin: 1em 0em;
  font-size: 1.5em;
  font-weight: 700;
}

#three-column .icon {
  position: relative;
  display: block;
  margin: 0px auto 0.8em auto;
  background: none;
  line-height: 150px;
  font-size: 4em;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 6px solid #67128f;
  text-align: center;
  color: #fff;
}

#three-column #tbox1,
#three-column #tbox2,
#three-column #tbox3 {
  float: left;
  width: 320px;
  padding: 80px 40px 80px 40px;
}

#three-column .title {
  text-align: center;
}

#three-column .title h2 {
  font-size: 1.6em;
}

#three-column .title .byline {
  padding-top: 0.5em;
  font-size: 0.9em;
  color: #858585;
}

#three-column .arrow-down {
  border-top-color: #292929;
}

ul.tools {
  margin: 0;
  padding: 0em 0em 0em 0em;
  list-style: none;
}

ul.tools li {
  display: inline-block;
  padding: 0em 0.2em;
  font-size: 4em;
}

ul.tools li span {
  display: none;
  margin: 0;
  padding: 0;
}

ul.tools li a {
  color: #fff;
}

ul.tools li a:before {
  display: inline-block;
  background: #1abc9c;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  line-height: 120px;
  text-align: center;
  color: #ffffff;
}

/*********************************************************************************/
/* AEC role + important readings                                                 */
/*********************************************************************************/
.aec-role {
  flex: 7;
  text-align: center;
}
.aec-role-title {
  flex: 7;
  margin-bottom: 2em;
  margin-top: 2em;
}
.aec-role-paragraph {
  font-size: 20px;
}

.imp-readings {
  flex: 3;
}

