
/*********************************************************************************/
/* Page                                                                          */
/*********************************************************************************/

#page-wrapper
{
  overflow: hidden;
  background: #2F1E28;
  padding: 3em 0em 6em 0em;
  text-align: center;
}

#page
{
}

/*********************************************************************************/
/* Content                                                                       */
/*********************************************************************************/

#content
{
  float: left;
  width: 700px;
  padding-right: 100px;
  border-right: 1px solid rgba(0,0,0,.1);
}

/*********************************************************************************/
/* Sidebar                                                                       */
/*********************************************************************************/

#sidebar
{
  float: right;
  width: 350px;
}

/*********************************************************************************/
/* Newsletter                                                                    */
/*********************************************************************************/

#newsletter
{
  overflow: hidden;
  padding: 8em 0em;
  background: #EDEDED;
  text-align: center;
}

#newsletter .title h2
{
  color: rgba(0,0,0,0.8);
}

#newsletter .content
{
  width: 600px;
  margin: 0px auto;
}

