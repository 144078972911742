html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0px!important;
  padding: 0px!important;
  background: #076598!important;
  font-family: "Questrial", sans-serif!important;
  font-size: 12pt!important;
  color: rgba(0, 0, 0, 0.6)!important;
  line-height: normal!important;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  color: #404040;
}

h1{
  font-weight: bold!important;
}

p,
ol,
ul {
  margin-top: 0;
}

ol,
ul {
  padding: 0;
  list-style: none;
}

p {
  line-height: 180%!important;
}

strong {
}

a {
  color: #2056ac;
}

a:hover {
  text-decoration: none;
}

.container {
  margin: 0px auto;
  width: 1400px;
}

/*********************************************************************************/
/* Form Style                                                                    */
/*********************************************************************************/

form {
}

form label {
  display: block;
  text-align: left;
  margin-bottom: 0.5em;
}

form .submit {
  margin-top: 2em;
  line-height: 1.5em;
  font-size: 1.3em;
}

form input.text,
form select,
form textarea {
  position: relative;
  -webkit-appearance: none;
  display: block;
  border: 0;
  background: #fff;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  border-radius: 0.5em;
  margin: 1em 0em;
  padding: 1.5em 1em;
  box-shadow: inset 0 0.1em 0.1em 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.15);
  -moz-transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  font-size: 1em;
  outline: none;
}

form input.text:hover,
form select:hover,
form textarea:hover {
}

form input.text:focus,
form select:focus,
form textarea:focus {
  box-shadow: 0 0 2px 1px #e0e0e0;
  background: #fff;
}

form textarea {
  min-height: 12em;
}

form .formerize-placeholder {
  color: #555 !important;
}

form ::-webkit-input-placeholder {
  color: #555 !important;
}

form :-moz-placeholder {
  color: #555 !important;
}

form ::-moz-placeholder {
  color: #555 !important;
}

form :-ms-input-placeholder {
  color: #555 !important;
}

form ::-moz-focus-inner {
  border: 0;
}

/*********************************************************************************/
/* Image Style                                                                   */
/*********************************************************************************/

.image {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.image img {
  display: block;
  width: 100%;
}

.image-full {
  display: block;
  width: 100%;
  margin: 0 0 3em 0;
}

.image-left {
  float: left;
  margin: 0 2em 2em 0;
}

.image-centered {
  display: block;
  margin: 0 0 2em 0;
}

.image-centered img {
  margin: 0 auto;
  width: auto;
}

/*********************************************************************************/
/* List Styles                                                                   */
/*********************************************************************************/

ul.style1 {
}


/*********************************************************************************/
/* Button Style                                                                  */
/*********************************************************************************/

.button {
  display: inline-block;
  margin-top: 0;
  padding: 0.8em 2em;
  background: #64abd1;
  line-height: 1.8em;
  letter-spacing: 1px;
  text-decoration: none;
  font-size: 1em;
  color: #fff;
}

.button:before {
  display: inline-block;
  background: #8dcb89;
  margin-right: 1em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  color: #272925;
}

.button-small {
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
