.article {
  overflow: hidden;
  padding: 5em 0em 0em 0em;
  background: #fff;
  text-align: left;
  padding-bottom: 2em;
}

.article ul,
.article ol {
  list-style-type: disc;
}

.article ul,
.article ul li {
  margin-left: 0.5em;
}

.article ul > p {
  padding-left: 2em;
}

.article ul > ul {
  padding-left: 2em;
}

.article ul > ul > li {
  padding-bottom: 1em;
}
.article h1 {
  padding-bottom: 1em;
}
