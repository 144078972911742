/*********************************************************************************/
/* Heading Titles                                                                */
/*********************************************************************************/

.title
{
  margin-bottom: 3em;
}

.title h2
{
  font-size: 2.8em;
}

.title .byline
{
  font-size: 1.1em;
  color: #6F6F6F;
}

/*********************************************************************************/
/* Header                                                                        */
/*********************************************************************************/

#header-wrapper
{
  overflow: visible;
  background: #076598;
}

#header
{
  text-align: center;
}

.side-buttons{
    text-align: right;
    margin-right: 9em;
    margin-left: 9em;
    background: none;
}

.contact{
    background: none;
}

/*********************************************************************************/
/* Logo                                                                          */
/*********************************************************************************/

#logo
{
  padding: 2em 0em 2em 0em;
}

#logo h1
{
  display: block;
  margin-bottom: 0.20em;
  padding: 0.20em 0.9em;
  font-size: 2.5em;
}

#logo a
{
  text-decoration: none;
  color: #FFF;
}

#logo span
{
  text-transform: uppercase;
  font-size: 2.90em;
  color: rgba(255,255,255,1);
}

#logo span a
{
  color: rgba(255,255,255,0.8);
}

/*********************************************************************************/
/* Social Icon Styles                                                            */
/*********************************************************************************/

ul.header-nav {
  margin: 0;
  padding: 2em 0em 0em 0em;
  list-style: none;
}

ul.header-nav li {
  display: inline-block;
  padding: 0em 0.1em;
  font-size: 2em;
}

ul.header-nav li span {
  display: none;
  margin: 0;
  padding: 0;
}

ul.header-nav li a {
  color: #fff;
}

ul.header-nav li a:before {
  display: inline-block;
  /* background: #4c93b9; */
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
