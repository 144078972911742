/*********************************************************************************/
/* contact                                                                        */
/*********************************************************************************/

.contact {
  overflow: hidden;
  padding: 5em 0em 0em 0em;
  background: #fff;
  text-align: center;
}

.contact .locations-section-heading {
  padding-bottom: 3em;
  font-size: 1.75em;
  line-height: 1.33;
}

.contact .locations-section-column {
  text-align: left;
  margin: 0 2em;
  width: 16em;
}

.contact .locations-section-column-icon{
  margin-bottom: 1em;
}

.contact .locations-section-row {
  width: fit-content;
  margin: 0 auto;
}

.contact .locations-section {
  background-color: #e3f1f9;
  padding: 3em;
}

.contact .contact-form-section {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  margin-bottom: 5em;
}

.contact .contact-form-section-form {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 5em;
  padding: 2em;
  border-radius: 7px 7px 0px 0px;
  margin: 0 auto;
  width: 50%;
}

.contact .contact-form-section .contact-input {

  height: 4em;
}

.ant-row.ant-form-item {
  /* margin: 1em 1em; */
  width: 100%;
  color: #e2e2e2;
}


.contact .contact-form-section .contact-form-submit {
  width: 100%;
}

.ant-row.ant-form-item{
  margin-right: 2em;
}


