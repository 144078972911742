.vision {
  overflow: hidden;
  padding: 5em 0em 5em 0em;
  background: #fff;
}

.vision .section {
  margin: 1em auto;
  padding-bottom: 2em;
  padding-top: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  line-height: 2em;
}

.vision .section ul {
  list-style-type: disc;
}

.vision .section ul,
.vision .section ul li {
  margin-left: 0.5em;
  padding-left: 1em;
}

.vision .no-border {
  border-bottom: 0px;
}
