/*********************************************************************************/
/* Services                                                                      */
/*********************************************************************************/

.services {
  margin-top: 2em;
  padding-top: 4em;
  padding-bottom: 4em;
  background-color: #e3f1f9;
  width: 100%;
}
.ser-snippet-title {
  text-align: center;
  margin-bottom: 3em;
}
