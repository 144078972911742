/*********************************************************************************/
/* Footer                                                                     */
/*********************************************************************************/

.footer
{
  overflow: hidden;
  padding: 5em 0em;
  /* border-top: 20px solid rgba(255,255,255,0.08); */
  background: #076598;
  text-align: center;
}

.footer p
{
  letter-spacing: 1px;
  font-size: 0.90em;
  color: rgba(255,255,255,0.6);
}

.footer a
{
  text-decoration: none;
  color: rgba(255,255,255,0.8);
}



/*********************************************************************************/
/* Social Icon Styles                                                            */
/*********************************************************************************/

ul.footer-nav {
  margin: 0;
  padding: 2em 0em 0em 0em;
  list-style: none;
}

ul.footer-nav li {
  display: inline-block;
  padding: 0em 0.1em;
  font-size: 2em;
}

ul.footer-nav li span {
  display: none;
  margin: 0;
  padding: 0;
}

ul.footer-nav li a {
  color: #fff;
}

ul.footer-nav li a:before {
  display: inline-block;
  background: #076598;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
