/*********************************************************************************/
/* flip-card                                                                     */
/*********************************************************************************/

.flip-card {
  height: 200px;
  width: 100%;
  margin: auto 1em;
  position: relative;
}
.react-card-flip {
  height: 100%;
  width: 100%;
}
.flip-card-front {
  height: 100%;
  width: 100%;
  position: absolute;
  display: table;
  background-color: #e3f1f9;
  border: 1px solid #076598;
  border-radius: 7px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.flip-card-front p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.flip-card-back {
  height: 100%;
  width: 100%;
  position: absolute;
  display: table;
  color: white;
  background-color: #076598;
  border: 1px solid #076598;
  border-radius: 7px;
  text-align: center;
}
.flip-card-back p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 5px;
}

.flip-card-front span {
  font-size: 2em;
  color: #076598;
}
