.staticbtn {
  background-color: #076598;
  padding: 16px;
  font-size: 16px;
  border: none;
  display: inline-block;
  padding: 0em 1.5em;
  text-decoration: none;
  font: 400 13.3333px Arial;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 60px!important;
  outline: 0;
  color: #fff;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #076598;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  z-index: 10;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background: #fff;
  border-radius: 7px 7px 0px 0px;
  color: #076598;
  cursor: pointer;
}

.staticbtn:hover {
  background: #fff;
  border-radius: 7px 7px 0px 0px;
  color: #076598;
}

.active {
  background: #fff;
  border-radius: 7px 7px 0px 0px;
  color: #076598;
}

.drop-active{
    background-color: #ddd;
}
