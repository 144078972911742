.article-preview {
  /* width: 290px;
    height: 180px; */
    background-color: #EFEFEF;
    margin: 1em;
    padding: 5px;
}
.art-pre-image {
  flex: 1;
  margin: auto 0;  
  height:100%;
}
.art-pre-content {
  flex: 3;
}

.art-pre-text {
  flex: 5;
}
.art-pre-button {
  margin: 0 auto;
  padding: 0;
  flex: 1;
}
.art-pre-title {
  /* font-size:14px;     */
}

.art-pre-paragraph {
  /* font-size:12px;     */
  margin: 1em;
}
